:root {
  --trolley-color: #5a5a5c;
}

.tram-container {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 3px solid var(--trolley-color);

  container-type: inline-size;

  position: relative;
  overflow: hidden;
}

.tram {
  max-width: 580px;
  max-height: 113px;

  animation: marquee 16s infinite ease-in-out;
}

@keyframes marquee {
  /*Left to right*/
  0% {
    transform: translateX(calc(0cqw - 100%)) scaleX(1);
  }
  49% {
    transform: translateX(calc(100cqw)) scaleX(1);
  }

  /*Right to left*/
  51% {
    transform: translateX(calc(100cqw)) scaleX(-1);
  }
  100% {
    transform: translateX(calc(0cqw - 100%)) scaleX(-1);
  }
}
